import { Component } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { Discountcoupon, UHSubscription } from 'app/subscription/models/subscription.model';

import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { GoogleTagManagerService } from 'app/utilities/services/google-tag-manager.service';
import { PriceWithDiscountData } from 'app/shared/components/price-with-discount/price-with-discount.component';
import { DataLayerSubscriptionService } from 'app/utilities/services/data-layer-subscription.service'
import { environment } from 'environments/environment.prod';
import { DiscountCouponsService } from 'app/order/services/discount-coupons.service';

@Component({
  selector: 'unh-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent {
  subscription$: Observable<UHSubscription>;
  checkoutStore$ = this.css.store$;
  priceData: PriceWithDiscountData;
  discountcoupon$: Discountcoupon
  policyPrivacyFileUrl = environment.policyPrivacyFileUrl;
  policyUsageFileUrl = environment.policyUsageFileUrl;
  constructor(
    private sbs: SubscriptionService,
    private css: CheckoutStoreService,
    private gtms: GoogleTagManagerService,
    private dls: DataLayerSubscriptionService,
    private ss: SubscriptionService,
    private ods: OriginDataService,
    private dcs: DiscountCouponsService
  ) {
    this.subscription$ = this.sbs.subscriptions$.pipe(
      map(subs => subs[0]),
      tap(s => this.setPriceData(s, this.ss.discountcoupon))
    );

    combineLatest(this.ss.discountcoupon$, this.sbs.subscriptions$).pipe(
      tap(
        ([dc, subs]) => {
          this.setPriceData(subs[0] as any, dc as any);
        })
    ).subscribe();

  }

  ngOnInit() {
    this.dcs.checkSubscriptionHasCoupon().subscribe(
      (couponApplied) => {
        if (couponApplied) {
          this.sbs.applySubscriptionDiscountCoupon(this.sbs.subscriptions[0].subscriptionid, this.dcs.activeCoupon.couponcode);
        }
      }
    );
    // GTM dataLayer event
    const payload =
      this.dls.getPurchaseDataForDatalayer(
        'product_group',
        'addToCard',
        this.ss.subscriptions[0],
        this.ods.getOriginData(),
        this.ss.discountcoupon
      );
    this.gtms.dispatchAddToCartFBPixel(payload);
  }

  setPriceData(subscription: UHSubscription, discountCoupon: Discountcoupon) {
    this.priceData = {
      prefix: 'R$',
      price: subscription.price.price,
      quantity: subscription.quantity,
      total: discountCoupon != null ? this.calculateDiscoutnPrice(subscription, discountCoupon) : subscription.TotalWithDiscount

    };
  }

  calculateDiscoutnPrice(subscription: UHSubscription, discountCoupon: Discountcoupon) {
    return (Number(subscription.TotalWithDiscount) - Number(discountCoupon.discount)).toString()
  }

  toggleAcceptTerms(internalname, valid: boolean) {
    this.css.set(internalname, { valid, data: null });
  }

  updateQuantity(type: 'increment' | 'decrement', quantity: number) {
    this.sbs.updateSubscriptionMembersQuantity(type, quantity).subscribe({
      complete: () => this.setPriceData(this.sbs.subscriptions[0], this.ss.discountcoupon)
    });
  }
}

