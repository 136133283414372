import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { CheckoutLayoutComponent } from './_layouts/checkout-layout/checkout-layout.component';

import { LayoutsModule } from './_layouts/layouts.module';
import { TestGenerateOrderComponent } from './test-generate-order.component';
import { MainGuardService } from './utilities/services/main-guard.service';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { MenuContentComponent } from './_layouts/menu-content/menu-content.component';
import { SubscriptionCanActivateGuard } from './subscription/services/subscription.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [],
    component: CheckoutLayoutComponent,
    data: {
      wizardTitle: 'Dados da compra',
      wizardMobileTitle: 'Confirmação da Compra',
    },
    children: [
      {
        path: 'order',
        canActivate: [MainGuardService],
        component: CheckoutComponent,
      },
      {
        path: 'subscription',
        canActivate: [SubscriptionCanActivateGuard],
        component: CheckoutComponent,
      },
    ]
  },
  {
    path: 'feedback',
    component: MenuContentComponent,
    canActivate: [MainGuardService],
    data: {
      wizardTitle: 'Confirmação da Compra',
      wizardMobileTitle: 'Confirmação da Compra',
    },
    loadChildren: './feedback/feedback.module#FeedbackModule'
  },
  {
    path: 'XUH19X/generate-order',
    component: TestGenerateOrderComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }), LayoutsModule],
  exports: [RouterModule, LayoutsModule]
})
export class AppRoutingModule { }
