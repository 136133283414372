import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

import { CCCVCDirective, CCExpirationDirective, CCNumberDirective, UHDOCDirective, UHEqualityDirective } from 'app/payment/directives';

import { CheckoutComponent } from './checkout.component';
import { PaymentOptionsComponent } from './sections/payment-options/payment-options.component';
import { BillingInfoComponent } from 'app/modules/checkout/sections/billing-info/billing-info.component';
import { SubscriptionPaymentComponent } from './sections/subscription-payment/subscription-payment.component';
import { SubscriptionDetailsComponent } from './sections/subscription-details/subscription-details.component';
import { DiscountCouponComponent } from './sections/discount-coupon/discount-coupon.component';
import { PaymentModule } from 'app/payment/payment.module';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule, 
    FormsModule,
    PaymentModule
  ],
  declarations: [
    PaymentOptionsComponent,
    CheckoutComponent,
    DiscountCouponComponent,
    BillingInfoComponent,
    SubscriptionPaymentComponent,
    SubscriptionDetailsComponent
  ],
  exports: [
    CheckoutComponent
  ]
})
export class CheckoutModule { }
