import { Injectable } from '@angular/core';
import { OrderService } from 'app/order/services/order.service';

import {
  DataLayerPayload,
  DataLayerPaymentTypeOrderProduct,
  DataLayerPaymentTypeOrder
} from 'app/utilities/services/models/gtm.model';

@Injectable({
  providedIn: 'root'
})
export class DataLayerOrderService {

  constructor() { }

  getPurchaseDataForDatalayer(
    payment_type: string,
    payment_method?: string,
    orderService?: any,
    origindata?: any
  ): DataLayerPayload {

    const order = orderService.order;
    const dc = order.orderproducts[0].discountcoupon;
    const coupon = dc && dc.couponcode || '';
    const products = order.orderproducts.map(op => {
      const isCourse = op.media_urls;
      if (isCourse) {
        return ({
          id: op.productid,
          name: op.title,
          price: op.price,
          image_url: op.media_urls.postthumbnailcover,
          brand: 'UNHIDE',
          quantity: op.quantity,
          coupon: (op.discountcoupon && op.discountcoupon.couponcode) || ''
        })
      }
      return {
        id: op.productid,
        name: op.title,
        price: op.price,
        brand: 'UNHIDE',
        quantity: op.quantity,
        coupon: (op.discountcoupon && op.discountcoupon.couponcode) || ''
      }
    }
    );

    const dataLayerPurchaseOrderProductsData: DataLayerPaymentTypeOrderProduct[] = order.orderproducts.map(op => ({
      id: op.orderproductid,
      name: op.title,
      description: op.description,
      price: op.price,
      quantity: op.quantity
    }));

    const dataLayerPurchaseOrderData: DataLayerPaymentTypeOrder = {
      id: products[0].id,
      totalprice: orderService.totalPrice.toString(),
      totalproducts: order.orderproducts.length,
      orderproducts: dataLayerPurchaseOrderProductsData,
      coupon
    };

    return {
      payment_type,
      payment_method,
      purchase: dataLayerPurchaseOrderData,
      products,
      ecommerce: {
        purchase: {
          actionField: {
            id: products[0].id,
            affiliation: origindata.origin,
            revenue: orderService.totalPrice,
            tax: 0,
            shipping: 0,
            coupon: coupon
          },
          products
        }
      }
    };
  }
}
