import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { CheckoutStoreService } from '../../order/services/checkout-store.service';
import { OrderService } from '../../order/services/order.service';
import { AuthGuardService } from './auth-guard.service';
import { OriginDataService } from './origin-data.service';

import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { SubscriptionService } from 'app/subscription/services/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class MainGuardService implements CanActivate {
  constructor(
    private store: CheckoutStoreService,
    private ags: AuthGuardService,
    private os: OrderService,
    private ors: OriginDataService,
    private subscriptionService: SubscriptionService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    this.store.clear(true);

    const resolveAuth = this.ags.canActivate(route);
    if (this.os.order != null) {
      return resolveAuth.pipe(map(() => true));
    }


    return resolveAuth.pipe(
      switchMap(() => {
        if (this.ors.getOriginData()) {
          const subscriptionid = this.ors.getOriginData().subscriptionid;
          const orderid = this.ors.getOriginData().orderid;
          if (subscriptionid) {
            return this.subscriptionService.getUserSubscriptions().pipe(
              map(res => res.dict.subscriptions),
              tap(subscriptions => {
                if (subscriptions.find(sub => sub.subscriptionid === this.ors.getOriginData().subscriptionid)) {
                  return of();
                }
                return throwError('Has no subscription');
              }),
            )
          }
          return this.os.getOrderById(orderid, true);

        }
      }),
      catchError(err => {
        this.ors.clearOriginData();
        return of(false);
      }),
      map(() => true)
    );
  }
}
