export const environment = {
  // defaults
  production: true,
  site: 'unhideconference',
  apipath: 'https://api.unhideschool.com/api',
  uhschoolpath: 'https://www.unhideschool.com',
  checkoutpath: 'https://checkout.unhideschool.com/order',
  uhmasterspath: 'https://www.unhideschool.com/unhidemasters',
  uhconfpath: 'https://unhideconference.com',
  uhawardspath: 'https://unhideconference.com/awards',
  vindipuburl: 'https://app.vindi.com.br:443/api/v1/public/payment_profiles',
  vindipubkey: 'M2ph2Z6EgtlXxjPIXJGfvk0Yes4InNNtZ5iCA0GX9gk',
  storage: {
    keys: {
      uhtoken: 'uh-authtoken',
      uhuser: 'uh-user',
      uhacceptlang: 'uh-acceptlang',
      uhorigin: 'uh-origindata'
    }
  },
  cookeiexpdays: 365,
  // customs
  orderhandlers: {
    vindi: 1,
    mock: 2,
    unhide: 3,
    vindiboleto: 4
  },
  RDStationToken: '58aad83bd07df2e81c0fa1fc5a3f7226',
  policyPrivacyFileUrl: 'https://drive.google.com/file/d/19y6D16DwaxSDe6G1xnGuSCiEFd-xq3lq/view?usp=sharing',
  policyUsageFileUrl: 'https://drive.google.com/file/d/1r4XeccYljhs9Wg0qhhpLjde7ec-WaL7A/view?usp=sharing'
};
