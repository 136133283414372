import { Injectable } from '@angular/core';
import { SubscriptionService } from './subscription.service';
import { SubscriptionApiService } from './subscription-api.service';
import { PaymentService } from 'app/payment/services/payment.service';
import { CreditcardData } from 'app/payment/models/creditcard.model';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { switchMap, publishLast, refCount, catchError, map, tap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UHSubscription } from '../models/subscription.model';
import { ObtrusiveLoaderService } from 'app/shared/components/obtrusive-loader/service/obtrusive-loader.service';
import { BillingFormModel, IBillingInfo } from 'app/order/models';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { NavigationHelpService } from 'app/utilities/services/navigation-helper.service';

import {
    GoogleTagManagerService,

} from 'app/utilities/services/google-tag-manager.service';

import {
    DataLayerPaymentTypeSubscriptionPlan,
    DataLayerPaymentTypeSubscription,
    DataLayerPayload
} from 'app/utilities/services/models/gtm.model';

import { DataLayerSubscriptionService } from 'app/utilities/services/data-layer-subscription.service'
import { UserService } from 'app/utilities/services';
import { SigninService } from 'app/signin/signin.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionPaymentService {
    constructor(
        private ss: SubscriptionService,
        private sapis: SubscriptionApiService,
        private ps: PaymentService,
        private css: CheckoutStoreService,
        private obls: ObtrusiveLoaderService,
        private ods: OriginDataService,
        private nhs: NavigationHelpService,
        private gtms: GoogleTagManagerService,
        private dls: DataLayerSubscriptionService,
        private userService: UserService,
        private signingService: SigninService
    ) { }

    private get store() {
        return this.css.store;
    }

    finishSubscriptionPayment(billingData: BillingFormModel) {
        // Start spinner
        this.obls.wait('PROCESS_SUBSCRIPTION');
        this.dispatchGTMEvents();
        const subscription = this.ss.subscriptions[0];
        const creditcarddata = new CreditcardData(this.store.creditcardData.data);

        const isUserLogged = this.userService.user.email == billingData.email
        const isAnynomous = of(!isUserLogged);
        const subs = isAnynomous.pipe(
            switchMap(isAnynomous => this.handlePostQuickLogin(isAnynomous, billingData)),
            switchMap(() => {
                if (!isUserLogged) {
                    return this.ss.updateSubscriptionOwner(subscription.subscriptionid)
                }
                return of('');
            }),
            switchMap(() => this.setSubscriptionHandler(subscription.subscriptionid)),
            switchMap(() => this.ps.getVindiToken(creditcarddata)),
            switchMap(res => {
                const gateway_token = res.payment_profile.gateway_token;
                return this.requestSubscriptionActivation(subscription, gateway_token);
            }),
            switchMap(() => this.sapis.checkActivatedSubscription(subscription.subscriptionid)),
            publishLast(),
            refCount(),
        );

        subs.subscribe({
            next: res => this.handleSubscriptionActivationSuccess(res),
            error: err => this.handleSubscriptionActivationError(err)
        });

        return subs;
    }

    private dispatchGTMEvents() {
        // GTM dataLayer
        const payload =
            this.dls.getPurchaseDataForDatalayer(
                'product_group',
                'creditcard',
                this.ss.subscriptions[0],
                this.ods.getOriginData(),
                this.ss.discountcoupon
            );
        this.gtms.dispatchAddPaymentInfo(payload);
        this.gtms.dispatchPaymentStarted(payload);
    }

    private setSubscriptionHandler(subscriptionid: number) {
        const subshandlerid = 3; // Vindi - Credit Card Handler
        return this.sapis.setSubscriptionHandler(subscriptionid, subshandlerid).pipe(
            catchError(err => { console.log(err); return of(err); })
        );
    }

    private requestSubscriptionActivation(subscription: UHSubscription, gatewaytoken: string): Observable<any> {
        const discountcoupon = this.ss.discountcoupon;
        const billinginfo: IBillingInfo = this.store.billingData.data;

        let payload: any = { ...billinginfo, gatewaytoken };

        if (discountcoupon != null) {
            payload = { ...payload};
        }

        return this.sapis.requestSubscriptionActivation(subscription.subscriptionid, payload);
    }

    private handleSubscriptionActivationSuccess(res) {
        const payload =
            this.dls.getPurchaseDataForDatalayer(
                'product_group',
                'creditcard',
                this.ss.subscriptions[0],
                this.ods.getOriginData(),
                this.ss.discountcoupon
            );
        this.gtms.dispatchSubscriptionSuccess(payload);

        // Redict to feedback
        this.nhs.navigateWithOriginData(`/feedback/membership`);

        // Stop spinner and clear checkout data
        this.obls.done('PROCESS_SUBSCRIPTION');
        this.css.clear(true);
    }

    private handleSubscriptionActivationError(err) {
        // GTM Datalayer
        const payload =
            this.dls.getPurchaseDataForDatalayer(
                'product_group',
                'creditcard',
                this.ss.subscriptions[0],
                this.ods.getOriginData(),
                this.ss.discountcoupon
            );
        this.gtms.dispatchPaymentFailed(payload);

        // Redict to feedback
        this.nhs.navigateWithOriginData(`/feedback/failed`);

        // Stop spinner and clear checkout data
        this.obls.done('PROCESS_SUBSCRIPTION');
        this.css.clear(true);
    }

    private getPurchaseDataForDatalayer(
        payment_type: string,
        payment_method: string
    ): DataLayerPayload {

        const subscription = this.ss.subscriptions[0];
        const dc = this.ss.discountcoupon;
        const coupon = dc && dc.couponcode || '';
        const totalprice = dc != null ? dc.discountedprice : subscription.price.price;

        const origindata = this.ods.getOriginData();

        const ecommerceProduct = {
            id: subscription.subscriptionplan.subsplanid,
            name: subscription.subscriptionplan.name,
            price: totalprice,
            brand: 'UNHIDE',
            quantity: 1,
            coupon
        };

        // dataLayer - Subscription Plan
        const dataLayerSubscriptionPlanData: DataLayerPaymentTypeSubscriptionPlan = {
            id: subscription.subscriptionplan.subsplanid,
            name: subscription.subscriptionplan.name,
            description: subscription.subscriptionplan.description.replace(/^<p>(.*)<\/p>$/g, '$1'),
            price: subscription.price.price
        };

        // dataLayer - Subscription
        const dataLayerSubscriptionData: DataLayerPaymentTypeSubscription = {
            id: subscription.subscriptionid,
            totalprice,
            totalproducts: 1,
            coupon: ecommerceProduct.coupon,
            subscriptionplan: dataLayerSubscriptionPlanData
        };

        return {
            payment_type,
            payment_method,
            products: [ecommerceProduct],
            purchase: dataLayerSubscriptionData,
            ecommerce: {
                purchase: {
                    actionField: {
                        id: ecommerceProduct.id,
                        affiliation: origindata.origin,
                        revenue: totalprice,
                        tax: 0,
                        shipping: 0,
                        coupon: coupon
                    },
                    products: [ecommerceProduct]
                }
            }
        };
    }

    handlePostQuickLogin(isAnynomous, billingData: BillingFormModel) {
        if (isAnynomous) {
            const countryid = this.userService.countries$.getValue().find(country => country.countrycode === billingData.country).countryid
            return this.userService.createQuickUser(billingData, countryid).pipe(
                map(res => res.dict.token),
                switchMap((token) => this.signingService.setCurrentOwnerAuthToken(token)),
                tap(() => this.ods.syncOriginDataToken()),
                switchMap(() => this.userService.getAvatar()),
            )
        }
        return of('');
    }
}
