import { Injectable } from '@angular/core';
import { throwError, of, from, Observable } from 'rxjs';
import { take, mergeMap, switchMap, toArray, map, tap, catchError } from 'rxjs/operators';
import { environment } from 'app/../environments/environment';
import { OrderService } from 'app/order/services/order.service';
import { CheckoutStoreService } from '../../order/services/checkout-store.service';
import { ObtrusiveLoaderService } from 'app/shared/components/obtrusive-loader/service/obtrusive-loader.service';
import { PurchaseCustomData, BillingPostData, BillingFormModel } from '../../order/models';

import {
  CreditcardInfo,
  VindiCardTokenResponse,
  CreditcardData
} from '../models/creditcard.model';

import { Paymethod, PaymentData } from 'app/payment/payment.model';
import { ApiService, ApiResponse } from 'uh-core';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { NavigationHelpService } from 'app/utilities/services/navigation-helper.service';

import {
  GoogleTagManagerService,

} from 'app/utilities/services/google-tag-manager.service';

import {
  DataLayerPaymentTypeOrder,
  DataLayerPaymentTypeOrderProduct,
  DataLayerPayload
} from 'app/utilities/services/models/gtm.model';

import { DataLayerOrderService } from 'app/utilities/services/data-layer-order.service'
import { UserService } from 'app/utilities/services';
import { SigninService } from 'app/signin/signin.service';
declare const RdIntegration;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  get order() {
    return this.orderService.order;
  }

  get store() {
    return this.storeService.store;
  }

  constructor(
    private api: ApiService,
    private orderService: OrderService,
    private oblService: ObtrusiveLoaderService,
    private storeService: CheckoutStoreService,
    private ods: OriginDataService,
    private nhs: NavigationHelpService,
    private gtms: GoogleTagManagerService,
    private dls: DataLayerOrderService,
    private userService: UserService,
    private signinService: SigninService
  ) { }

  getVindiToken(creditcardInfo: CreditcardInfo) {
    return this.api
      .externalRequest<VindiCardTokenResponse>(
        'post',
        environment.vindipuburl,
        creditcardInfo,
        `Basic ${btoa(environment.vindipubkey + ':')}`
      )
      .pipe(
        mergeMap(r => {
          if (r.errors) {
            return throwError(r.errors);
          }

          return of(r);
        }),
        take(1)
      );
  }

  payOrder(paymethod: Paymethod): void {
    // Start spinner
    this.oblService.wait('PROCESS_PAYMENT');

    // GTM dataLayer
    const payload =
      this.dls.getPurchaseDataForDatalayer(
        'product',
        paymethod,
        this.orderService,
        this.ods.getOriginData()
      );
    this.gtms.dispatchAddPaymentInfo(payload);
    this.gtms.dispatchPaymentStarted(payload);

    // Check paymethod
    this.processPaymentByPaymethod(paymethod, this.storeService.billingData.data);
  }

  processPaymentByPaymethod(paymethod: Paymethod, billingData: BillingFormModel) {
    const orderhandlerid = environment.orderhandlers[paymethod];

    const paymethodMapping = {
      vindi: () => this.getGatewayToken(paymethod),
      vindiboleto: () => this.requestPayment('vindiboleto', billingData),
      unhide: () => this.requestPayment('unhide', billingData)
    };

    this.updateOrderProductCustomData()
      .pipe(
        switchMap(res => {
          return this.setOrderHandler(orderhandlerid);
        })
      )
      .subscribe(() => paymethodMapping[paymethod](), err => this.onPaymentError(paymethod));
  }

  updateOrderProductCustomData(): Observable<any[] | boolean> {
    // if (this.rd.ignorePartakers) {
    return of(true);
    // }

    const purchasesData = this.store.partakersData.data;

    return from(Object.keys(purchasesData)).pipe(
      mergeMap(opid => {
        return this.orderService
          .updateOrderProductById(parseInt(opid, 10), new PurchaseCustomData(purchasesData[opid]))
          .pipe(take(1));
      }),
      toArray()
    );
  }

  setOrderHandler(orderhandlerid: number) {
    const current_orderstate = this.order.orderstate.internalname;

    if (current_orderstate === 'checking_out') {
      return this.orderService.setOrderHandler(orderhandlerid);
    }

    if (current_orderstate === 'waiting_payment') {
      return of('Waiting Payment');
    }

    return throwError(
      `Order isn't either in "checking_out" or "waiting_payment" state
      [ current state = ${current_orderstate} ]`
    );
  }

  getGatewayToken(paymethod: Paymethod) {
    const installments = this.store.creditcardData.data.installments;
    const creditcarddata = new CreditcardData(this.store.creditcardData.data);

    this.getVindiToken(creditcarddata).subscribe(
      res => {
        this.requestPayment('vindi', this.store.billingData.data, installments, res.payment_profile.gateway_token);
      },
      err => {
        console.log('ERROR [getVindiToken]:', err);

        // Test pourpose only
        if (!environment.production) {
          this.requestPayment('vindi', this.store.billingData.data);
        } else {
          this.onPaymentError(paymethod);
        }
      }
    );
  }

  requestPayment(paymethod: Paymethod = null, billingData?: BillingFormModel, installments = '1', gatewaytoken = '') {
    const billingdata = new BillingPostData(this.store.billingData.data);
    const paymentdata = new PaymentData(billingdata, installments, gatewaytoken);
    const isUserLogged = this.userService.user.email == billingData.email;
    const isAnynomous = of(!isUserLogged);
    const requestPayment = isAnynomous.pipe(
      switchMap(isAnynomous => this.handlePostQuickLogin(isAnynomous, billingData)),
      switchMap(() => {
        if (!isUserLogged) {
          return this.orderService.updateOrderOwner(this.order.orderid);
        }
        return of('');
      }),
      switchMap(() => this.orderService.requestOrderPayment(paymentdata)),
      tap(res => this.finishPayment(res, paymethod)),
      catchError(err => {
        this.onPaymentError(paymethod);
        return of('Payment Failed', err);
      })
    )

    requestPayment.subscribe();
  }

  finishPayment(res: ApiResponse<any>, paymethod: Paymethod) {
    switch (paymethod) {
      case 'unhide':
      case 'vindi': {
        return this.confirmPayment(paymethod);
      }

      case 'vindiboleto':
        {
          this.oblService.done('PROCESS_PAYMENT');

          const boleto_url = btoa(res.dict.boleto_url).replace('/', '%2f');

          // GTM dataLayer event
          const payload =
            this.dls.getPurchaseDataForDatalayer(
              'product',
              paymethod,
              this.orderService,
              this.ods.getOriginData()
            );
          this.gtms.dispatchBankSlipGenerated(payload);

          this.nhs.navigateWithOriginData(`/feedback/bankslip/${boleto_url}`);
        }
        break;

      default: {
        this.onPaymentError(paymethod);
      }
    }
  }

  confirmPayment(paymethod: Paymethod) {
    this.orderService.checkOrderPaid().subscribe(
      res => {
        const origindata = this.ods.getOriginData();
        const type = origindata.origin.replace(/^unhide([^:]+):.*$/g, '$1');

        // GTM dataLayer event
        const payload =
          this.dls.getPurchaseDataForDatalayer(
            'product',
            paymethod,
            this.orderService,
            this.ods.getOriginData()
          );
        this.gtms.dispatchPurchaseSuccess(payload);

        const feedbackPath = type !== 'conference'
          ? type === 'school' ? 'membership' : type
          : 'success';
        if (environment.production) {
          this.dispatchRdStationEvent(payload);
        }

        this.nhs.navigateWithOriginData(`/feedback/${feedbackPath}`);

        // Stop spinner
        this.oblService.done('PROCESS_PAYMENT');
      },
      err => this.onPaymentError(paymethod)
    );
  }

  dispatchRdStationEvent(payload: DataLayerPayload) {
    try {
      if (payload && payload.ecommerce != undefined) {
        var data_array = [
          { name: 'id', value: payload.purchase.id },
          { name: 'nome', value: this.storeService.billingData.data.fullname },
          { name: 'email', value: this.storeService.billingData.data.email },
          { name: 'produto', value: payload.ecommerce.purchase.products[0].name },
          { name: 'metodo_pagamento', value: payload.payment_method },
          { name: 'valor', value: payload.purchase.totalprice },
          { name: 'cupom', value: payload.purchase.coupon },
          { name: 'identificador', value: 'Pedido_Finalizado' },
          { name: 'token_rdstation', value: environment.RDStationToken }
        ];
        RdIntegration.post(data_array);
      }
    } catch (e) {
      console.log(e);
    }
  }

  onPaymentError(paymethod: Paymethod) {
    // GTM Datalayer
    const payload =
      this.dls.getPurchaseDataForDatalayer(
        'product',
        paymethod,
        this.orderService,
        this.ods.getOriginData()
      );
    this.gtms.dispatchPaymentFailed(payload);

    // Redict to feedback
    this.nhs.navigateWithOriginData(`/feedback/failed`);

    // Stop spinner
    this.oblService.done('PROCESS_PAYMENT');
  }

  getPurchaseDataForDatalayer(payment_type: string, payment_method: string): DataLayerPayload {
    const order = this.orderService.order;
    const origindata = this.ods.getOriginData();

    const dc = this.orderService.order.orderproducts[0].discountcoupon;
    const coupon = dc && dc.couponcode || '';

    const products = order.orderproducts.map(op => ({
      id: op.productid,
      name: op.title,
      price: op.price,
      brand: 'UNHIDE',
      quantity: op.quantity,
      coupon: (op.discountcoupon && op.discountcoupon.couponcode) || ''
    }));

    const dataLayerPurchaseOrderProductsData: DataLayerPaymentTypeOrderProduct[] = order.orderproducts.map(op => ({
      id: op.orderproductid,
      name: op.title,
      description: op.description,
      price: op.price,
      quantity: op.quantity
    }));

    const dataLayerPurchaseOrderData: DataLayerPaymentTypeOrder = {
      id: products[0].id,
      totalprice: this.orderService.totalPrice.toString(),
      totalproducts: order.orderproducts.length,
      orderproducts: dataLayerPurchaseOrderProductsData,
      coupon
    };

    return {
      payment_type,
      payment_method,
      purchase: dataLayerPurchaseOrderData,
      products,
      ecommerce: {
        purchase: {
          actionField: {
            id: products[0].id,
            affiliation: origindata.origin,
            revenue: this.orderService.totalPrice,
            tax: 0,
            shipping: 0,
            coupon: coupon
          },
          products
        }
      }
    };
  }

  handlePostQuickLogin(isAnynomous, billingData: BillingFormModel) {
    if (isAnynomous) {
      const countryid = this.userService.countries$.getValue().find(country => country.countrycode === billingData.country).countryid
      return this.userService.createQuickUser(billingData, countryid).pipe(
        map(res => res.dict.token),
        switchMap((token) => this.signinService.setCurrentOwnerAuthToken(token)),
        tap(() => this.ods.syncOriginDataToken()),
        switchMap(() => this.userService.getAvatar()),
        take(1)
      )
    }
    return of('');
  }
}
