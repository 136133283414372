import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs';

import { Paymethod } from 'app/payment/payment.model';
import { CreditcardFormModel } from 'app/payment/models/creditcard.model';

import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { OriginDataService } from '../../../../utilities/services/origin-data.service';
import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { SubscriptionPaymentService } from 'app/subscription/services/subscription-payment.service';

@Component({
  selector: 'uh-subscription-payment',
  styleUrls: ['subscription-payment.component.scss'],
  templateUrl: './subscription-payment.component.html'
})
export class SubscriptionPaymentComponent implements OnInit, OnDestroy {
  paymethod: Paymethod;
  creditcardData: CreditcardFormModel;

  subs = new Subscription();

  @ViewChild('f') form: NgForm;

  get fromEmptyModel(): CreditcardFormModel {
    return new CreditcardFormModel();
  }

  get fromStoredModel(): CreditcardFormModel {
    return { ...this.cs.store.creditcardData.data };
  }

  get billingcountry() {
    const billingdata = this.cs.get('billingData').data;
    return billingdata && billingdata.country;
  }

  get origin(): string {
    return this.ors.getOriginData().origin;
  }

  get discountcoupon$() {
    return this.ss.discountcoupon$;
  }

  constructor(
    private cs: CheckoutStoreService,
    private ors: OriginDataService,
    private ss: SubscriptionService,
  ) { }

  get currentSubscription() {
    return this.ss.subscriptions[0];
  }

  ngOnInit() {
    this.updateFormModel();

    const formValueChangesSubscription = this.form.valueChanges.subscribe(
      () => this.cs.set('creditcardData', {
        data: this.form.value,
        valid: this.form.valid
      })
    );

    this.subs.add(formValueChangesSubscription);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updateFormModel(clear = false) {
    if (!this.cs.store.creditcardData.data || clear) {
      this.creditcardData = this.fromEmptyModel;
    } else {
      this.creditcardData = this.fromStoredModel;
    }
  }

  resetForm() {
    if (!this.form.valid) {
      this.updateFormModel(true);
    }
  }

  onAppliedCoupon(couponcode: string) {
    const subscriptionid = this.currentSubscription.subscriptionid;
    this.ss.applySubscriptionDiscountCoupon(subscriptionid, couponcode);
  }

  onRemovedCoupon() {
    this.ss.discountcoupon = null;
  }
}
