import { Component, Output, EventEmitter } from '@angular/core';

import { first, map, tap, filter } from 'rxjs/operators';

import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { Observable, combineLatest } from 'rxjs';
import { Discountcoupon, UHSubscription } from 'app/subscription/models/subscription.model';

@Component({
  selector: 'uh-subscription-resume',
  templateUrl: './subscription-resume.component.html',
  styleUrls: ['./subscription-resume.component.scss']
})
export class SubscriptionResumeComponent {
  subscription$: Observable<UHSubscription>;
  discountcoupon$: Observable<Discountcoupon>;
  withDiscountCoupon = false;

  priceData = null;

  @Output() uhGoToCart = new EventEmitter();

  constructor(
    private ss: SubscriptionService,
    private sbs: SubscriptionService,
  ) {
    this.subscription$ = this.ss.subscriptions$.pipe(
      filter(subs => subs.length > 0),
      map(subs => subs[0]), tap(sub => {
        this.setPriceData(sub, this.ss.discountcoupon);

      }));
    this.discountcoupon$ = this.ss.discountcoupon$;

    const isTeamPlan = this.ss.subscriptions[0].subscriptionplan.rootplan === 'unhidemonthlybusiness';
    if (isTeamPlan) {
      combineLatest(this.ss.discountcoupon$, this.sbs.subscriptions$).pipe(
        tap(
          ([dc, subs]) => {
          this.setPriceData(subs[0] as any, dc as any);
          })
      ).subscribe();
    } else {
      this.discountcoupon$.pipe(
        tap(dc => {
          this.withDiscountCoupon = dc != null;
          this.setPriceData(this.ss.subscriptions[0], dc);
        })
      ).subscribe();
    }

  }

  setPriceData(subscription: UHSubscription, discountCoupon: Discountcoupon) {
    this.priceData = {
      prefix: 'R$',
      price: subscription.TotalWithDiscount,
      quantity: subscription.quantity,
      total: subscription.total
    };
  }

  getDescriptionAsText(description: string): string {
    if (description === null || typeof description === 'undefined') {
      return;
    }

    const divEl = document.createElement('div');
    divEl.innerHTML = description;
    return divEl.textContent;
  }

  goToCartStep() {
    this.uhGoToCart.emit();
  }
}
