import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from 'app/order/models';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { OrderService } from 'app/order/services/order.service';
import { Paymethod } from 'app/payment/payment.model';
import { PaymentService } from 'app/payment/services/payment.service';
import { SubscriptionPaymentService } from 'app/subscription/services/subscription-payment.service';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'uh-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  order: Order = null;
  paymentType: Paymethod;

  isOrder = this.ar.snapshot.routeConfig.path === 'order'
  get acceptedTerms() {
    return this.cs.get('acceptUsageTerms').valid;
  }

  usageTermsUrl = environment.policyUsageFileUrl;
  privacyTermsUrl = environment.policyPrivacyFileUrl;

  get validPaymentData() {
    return this.cs.get('creditcardData').valid;
  }

  get validBillingData() {
    return this.cs.get('billingData').valid;
  }

  get isPaymentEnabled() {
    if (this.paymentType == 'vindiboleto') {
      return this.acceptedTerms && this.validBillingData
    }
    return this.acceptedTerms && this.validBillingData && this.validPaymentData
  }

  constructor(
    private orderService: OrderService,
    private originDataService: OriginDataService,
    private paymentService: PaymentService,
    private cs: CheckoutStoreService,
    private ar: ActivatedRoute,
    private subscriptionPayment: SubscriptionPaymentService,

  ) { }

  ngOnInit() {
    this.orderService.order$.subscribe(order => {
      this.order = order;
      // this.totalvalue = this.orderService.totalPrice;
      // this.totalWithDiscount = this.orderService.totalWithDiscount;
      // this.maxInstallments = this.getInstallmentsByProduct();
      // this.prepareInstallments();
    })
  }

  getAutoAppliedCupon() {
    if (this.originDataService.getOriginData().origin !== 'unhideconference:wacom') {
      const couponcode = this.originDataService.getOriginData().couponcode;
      return couponcode;
    }

    return 'COMBOWACOM2019';
  }

  onSubmitPayment() {
    if (this.isPaymentEnabled) {
      if (this.isOrder) {
        return this.paymentService.payOrder(this.paymentType);
      }

      return this.subscriptionPayment.finishSubscriptionPayment(this.cs.billingData.data);
    }
  }

  hanldePaymentTypeChanged(paymentType) {
    this.paymentType = paymentType;
  }

  toggleAcceptTerms(internalname, accept: boolean): void {
    this.cs.set(internalname, { data: null, valid: accept });
  }

}
