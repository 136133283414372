import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SigninService } from 'app/signin/signin.service';
import { SigninFormComponent } from 'app/signin/signin-form/signin-form.component';
import { OrderService } from 'app/order/services/order.service';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SubscriptionService } from 'app/subscription/services/subscription.service';

@Component({
  selector: 'uh-signin-dialog',
  templateUrl: './signin-dialog.component.html',
  styleUrls: ['./signin-dialog.component.scss']
})
export class SigninDialogComponent {
  @ViewChild(SigninFormComponent) sform: SigninFormComponent;

  get email() {
    return this.data.email;
  }

  get orderid() {
    return this.data.orderid;
  }

  get orderstate() {
    return this.os.order.orderstate.internalname;
  }

  get showOrButton() {
    return this.data.orbutton != null;
  }

  get orbuttontext() {
    return this.data.orbutton;
  }

  constructor(
    private dialogRef: MatDialogRef<SigninDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private ss: SigninService,
    private os: OrderService,
    private subscriptionService: SubscriptionService
  ) { }

  closeDialog(data) {
    this.dialogRef.close(data);
  }

  onSubmitLogin(fd: { username: string, password: string }) {
    if (this.orderid) {
      this.loginToBuyOrder(fd)
    }
    this.loginToBuySubscription(fd);

  }

  loginToBuyOrder(fd) {
    const forceOrderStateToNew = (
      this.orderstate !== 'new' ? this.os.cancelCheckoutByOrderId() : of(true)
    );
    forceOrderStateToNew.pipe(
      switchMap(() => this.ss.login(fd.username, fd.password, this.orderid)),
      switchMap(() => this.os.checkoutOrderById(true)),
    ).subscribe(
      res => this.dialogRef.close(true),
      err => {
        this.sform.clearForm();
        this.sform.validationError.invalid = true;
      }
    );
  }

  loginToBuySubscription(fd) {
    this.ss.login(fd.username, fd.password, null, this.subscriptionService.subscriptions[0].subscriptionid).pipe(
    ).subscribe(
      res => this.dialogRef.close(true),
      err => {
        this.sform.clearForm();
        this.sform.validationError.invalid = true;
      }
    );
  }
}
