import { Component } from '@angular/core';
import {
  Routes,
  Router,
  Route,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';

import { animRoutes, fadeInOut } from './animations';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';

import { CheckoutStoreModel } from 'app/order/models';
import { filter, take, tap } from 'rxjs/operators';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { NavigationHelpService } from 'app/utilities/services/navigation-helper.service';
import { GoogleTagManagerService } from 'app/utilities/services/google-tag-manager.service';
import { OrderService } from 'app/order/services/order.service';
import { DiscountCouponsService } from 'app/order/services/discount-coupons.service';
import { DataLayerOrderService } from 'app/utilities/services/data-layer-order.service';
import { SubscriptionService } from 'app/subscription/services/subscription.service';

@Component({
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss'],
  animations: [animRoutes, fadeInOut] // register the animation
})
export class CheckoutLayoutComponent {
  store: CheckoutStoreModel;
  routes: any[];
  isOrder = false;

  constructor(
    private cs: CheckoutStoreService,
    private ods: OriginDataService,
    private gtms: GoogleTagManagerService,
    private os: OrderService,
    private dcs: DiscountCouponsService,
    private ss: SubscriptionService,
  ) {
    this.cs.store$.subscribe(store => (this.store = store));
    this.beginCheckoutSubscription(this.ods.getOriginData('couponcode'));
    this.beginCheckoutProduct(this.ods.getOriginData('couponcode'));
    this.routes = [{ path: "checkout", desktopLabel: 'Dados da Compra', mobileLabel: 'Dados' }, { path: "feedback", desktopLabel: 'Confirmação da Compra', mobileLabel: 'Confirmação' }];
    this.isOrder = location.pathname.includes('order');
  }

  beginCheckoutSubscription(couponcode) {
    this.os.onAppliedCoupon$
      .pipe(
        filter(hasAppliedCoupon => hasAppliedCoupon != null),
        take(1),
      ).subscribe((hasAppliedCoupon) => {
        const appliedCoupon = hasAppliedCoupon ? this.dcs.activeCouponcode : '';
        const couponCode = couponcode ? couponcode : appliedCoupon;
        this.gtms.dispatchBeginCheckoutProduct(this.os.order.orderproducts[0], couponCode, this.ods.getOriginData().origin);
      })
  }

  beginCheckoutProduct(couponcode) {
    this.ss.onCouponApplied$
      .pipe(
        filter(hasAppliedCoupon => hasAppliedCoupon != null),
        take(1),
      ).subscribe((hasAppliedCoupon) => {
        const appliedCoupon = hasAppliedCoupon ? this.dcs.activeCouponcode : '';
        const couponCode = couponcode ? couponcode : appliedCoupon;
        return this.gtms.dispatchBeginCheckoutSubscription(this.ss.subscriptions[0], couponCode, this.ods.getOriginData().origin);
      })
  }
}
