import { Injectable } from '@angular/core';

import { take, switchMap, catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { OrderService } from './order.service';

import { OriginDataService } from '../../utilities/services/origin-data.service';
import { GraphqlClientApiService } from 'app/utilities/services/graphql-client-api.service';
import { FetchActivePromotionQuery } from 'app/utilities/graphql/get_active_promotion_string.query';
import { getActivePromotionCouponQuery } from 'app/utilities/graphql/get_active_promottion_coupon.query';
import { getProductPromotions } from 'app/utilities/graphql/get_product_promotions.query';
import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { getSubscriptionCoupons } from 'app/utilities/graphql/get_subscription_promotions.query';

import { DiscountCouponModel } from '../models/discount-coupon.model';
@Injectable({
  providedIn: 'root'
})
export class DiscountCouponsService {
  hasActivePromo = false;
  activeCouponcode = '';
  activeCoupon: DiscountCouponModel = null;

  constructor(
    private ods: OriginDataService,
    private orderService: OrderService,
    private subscriptionService: SubscriptionService,
    private gcapis: GraphqlClientApiService,
  ) { }

  get origin() {
    return this.ods.getOriginData().origin;
  }


  applyCouponToOrder(couponcode: string): Observable<any> {
    return this.orderService.requestAndUpdateOrder(
      'GET',
      `/order/adddiscountcoupontoorder/${this.orderService.order.orderid
      }/${couponcode.toUpperCase()}`,
      true
    );
  }

  clearCouponFromOrder(): Observable<any> {
    return this.orderService.requestAndUpdateOrder(
      'GET',
      `/order/cleardiscountcouponfromorder/${this.orderService.order.orderid}`,
      true
    );
  }

  checkAutoApplicableCupons(): Observable<any> {
    return this.clearCouponFromOrder().pipe(
      // ACTIVE PROMOTION
      switchMap(() => this.checkActivePromotion()),
      switchMap(couponcode => this.applyCouponToOrder(couponcode).pipe(
        map(() => couponcode)),
      ),
      switchMap(couponcode => {
        if (couponcode) {
          return this.getPromoInfo(couponcode).pipe(
            tap(promotioncoupon => {
              this.orderService.configCouponDiscountWithoutApply(promotioncoupon)
            }),
            map((e) => {
              return true
            })
          )
        }
      }),
      // OTHER COUPONS
      catchError(res => {
        return this.applyCouponToOrder('LOTE3402019');
      }),
      catchError(res => {
        return this.applyCouponToOrder('UNHIDEINSPIRATION50');
      }),
      catchError(err => {
        return this.applyCouponToOrder('SUBSCRIBER3002019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('NEUROMANCER102019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('NEUROMANCER402019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('uhalphauser50');
      }),
      catchError(err => {
        return this.applyCouponToOrder('AWARDS1002019');
      }),
      take(1),

    );
  }

  handlePromoByInfinityCode() {
    if (this.ods.getOriginData().couponcode && this.ods.getOriginData().couponcode !== undefined) {
      this.hasActivePromo = true
      return of(this.ods.getOriginData().couponcode)
    }
    return throwError('None Active Promotion')
  }

  checkActivePromotion() {
    const obs = this.gcapis.graphqlRequest(FetchActivePromotionQuery).pipe(
      map(res => res.Campaign.all.items),
      switchMap(([campaign]) => {
        if (campaign.active) {
          this.activeCoupon = campaign.discountCoupon
          return of(campaign.discountCoupon.couponcode)
        }
        return this.handlePromoByInfinityCode()
      }),
      catchError(() => {
        return of('')
      }),
      map((couponcode: any) => {
        return couponcode;
      })
    );

    obs.pipe(
      switchMap(
        (data) => {
          this.hasActivePromo = true;
          if (this.ods.getOriginData() && this.ods.getOriginData().couponcode && this.ods.getOriginData().couponcode !== undefined) {
            this.activeCouponcode = this.ods.getOriginData().couponcode;
            return this.getPromoInfo(this.activeCouponcode).pipe(
              map(coupon => {
                {
                  this.activeCoupon = coupon;
                  this.activeCouponcode= coupon.couponcode;
                }
                return coupon.couponcode
              }
              )
            )
          } else {
            this.activeCouponcode = data;
            return of('');
          }
        }
      )
    ).subscribe({
      error: () => {
        if (this.ods.getOriginData() && this.ods.getOriginData().couponcode !== undefined) {
          this.activeCouponcode = this.ods.getOriginData().couponcode
          return this.hasActivePromo = true
        } else {
          return this.hasActivePromo = false

        }
      }
    });

    return obs;
  }

  getPromoInfo(couponcode: string) {
    const query = getActivePromotionCouponQuery;
    const variables = {
      couponcode,
      today: (new Date()).toISOString()
    }
    this.gcapis.graphqlRequest(query, variables).subscribe();
    return this.gcapis.graphqlRequest(query, variables).pipe(map(res => res.DiscountCoupon.all.items[0]));
  }

  checkProductHasCoupon() {
    const query = getProductPromotions;
    const variables = {
      productid: this.orderService.order.orderproducts[0].productid
    }
    return this.checkActivePromotion().pipe(
      switchMap(couponcode => {
        return this.hasActivePromo ? this.gcapis.graphqlRequest(query, variables) : of(null)
      }),
      map(res => res ? res.Product.item.discountcoupons : null),
      map(discountcoupons => discountcoupons ? discountcoupons.some(cp => cp.couponcode.toLowerCase() == this.activeCouponcode.toLowerCase()) : false),
      switchMap(hasCouponcode => hasCouponcode ? this.getPromoInfo(this.activeCouponcode.toUpperCase()) : of(null)),
      tap((couponcode) => {
        this.orderService.configCouponDiscountWithoutApply(couponcode);
      }));
  }

  checkSubscriptionHasCoupon() {
    return this.checkActivePromotion().pipe(
      switchMap(() => {
        const variables = {
          subscriptionPlanId: this.subscriptionService.subscriptions[0].subscriptionplan.rootplanid
        }
        return this.hasActivePromo ? this.gcapis.graphqlRequest(getSubscriptionCoupons, variables) : of(null)
      }),
      map(res => res.SubscriptionPlan.item.discountcoupons),
      map(discountcoupons => discountcoupons.some(cp => cp.couponcode.toLowerCase() == this.activeCouponcode.toLowerCase())),
      tap((hasCouponcode) => {
        if (hasCouponcode) {
          this.subscriptionService.dispatchSubscriptionPromoApplied(this.activeCoupon)
        }
      })
    )
  }
}

