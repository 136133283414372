import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { RouteDataService } from '../../../utilities/services/route-data.service';
import { UserService } from '../../../utilities/services';
import { OriginDataService } from 'app/utilities/services/origin-data.service';

import { SigninDialogComponent } from 'app/dialogs/signin-dialog/signin-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'uh-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  subs = new Subscription();
  user = null;

  get origin() {
    return this.ods.getOriginData().origin;
  }

  get orderid() {
    return this.ods.getOriginData().orderid;
  }

  constructor(
    private userService: UserService,
    private ods: OriginDataService,
    private rd: RouteDataService,
    private ds: MatDialog
  ) { }

  ngOnInit() {
    this.subs.add(this.userService.user$.subscribe(u => (this.user = u)));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  backToOrigin() {
    window.location.href = (
      this.rd.getOriginUrl(this.origin)
      + '?_c='
      + this.ods.originDataToHash(this.ods.getOriginData())
    );
  }

  openLoginDialog(data: { [key: string]: any }) {
    this.ds.open(SigninDialogComponent, {
      data: { ...data, orderid: this.orderid }
    });
  }
}
