import { Paymethod } from 'app/payment/payment.model';

export interface BaseCreditCardModel {
  holder_name: string;
  card_number: string;
  card_cvv: string;
}

export interface CreditcardInfo extends BaseCreditCardModel {
  card_expiration?: string;
}

export class CreditcardFormModel implements BaseCreditCardModel {
  holder_name = '';
  card_number = '';
  card_cvv = '';
  exp_month = '';
  exp_year = '';
  installments = '1';
}

export class CreditcardData implements CreditcardInfo {
  holder_name = '';
  card_number = '';
  card_expiration = '';
  card_cvv = '';

  constructor(cfm?: CreditcardFormModel) {
    if (cfm != null) {
      this.holder_name = cfm.holder_name.toUpperCase();
      this.card_number = cfm.card_number;
      this.card_cvv = cfm.card_cvv;
      this.card_expiration = CreditcardData.getExpirationDate(
        cfm.exp_month,
        cfm.exp_year
      );
    }
  }

  static getExpirationDate(expmonth: string | number, expyear: string | number) {
    return `${expmonth}/${expyear}`;
  }
}

export interface VindiCardTokenResponse {
  payment_profile: { gateway_token: string };
  errors?: {
    id: string;
    parameter: string;
    message: string;
  }[];
}
