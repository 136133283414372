import { Injectable } from '@angular/core';
import { DataLayerBeginCheckoutPayload, DataLayerGA4ItemModel, DataLayerPayload } from './models/gtm.model';
import { BillingPostData, OrderProduct } from 'app/order/models';
import { UHSubscription } from 'app/subscription/models/subscription.model';

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  dispatchPaymentStarted(payload: DataLayerPayload) {
    this.dispatchCustomEvent('initiate_checkout', payload);
  }

  dispatchBeginCheckoutProduct(orderProduct: OrderProduct, coupon: string, affiliation) {
    const item = this.beginCheckoutProductItemBuilder(orderProduct, coupon, affiliation);
    const data: DataLayerBeginCheckoutPayload = {
      items: [item],
      value: Number(orderProduct.price),
      coupon,
      currency: 'BRL'
    };
    const event = 'begin_checkout';
    dataLayer.push({ event, ...data });
  }

  dispatchBeginCheckoutSubscription(subscription: UHSubscription, coupon: string, affiliation) {
    const item = this.beginCheckoutSubscriptionItemBuilder(subscription, coupon, affiliation);
    const data: DataLayerBeginCheckoutPayload = {
      items: [item],
      value: Number(subscription.price.price),
      coupon,
      currency: 'BRL'
    };
    const event = 'begin_checkout';
    dataLayer.push({ event, ...data });
  };

  dispatchGenerateLeadOrder(payload: BillingPostData, orderProduct: OrderProduct) {
    const lead = this.buildLeadDataOrder(payload, orderProduct);
    const event = 'generate_lead';
    dataLayer.push({ event, ...lead });
  }

  dispatchGenerateLeadSubscription(payload: BillingPostData, orderProduct: UHSubscription) {
    const lead = this.buildLeadDataSubscription(payload, orderProduct);
    const event = 'generate_lead';
    dataLayer.push({ event, ...lead });
  }


  dispatchAddPaymentInfo(payload: DataLayerPayload) {
    this.dispatchCustomEvent('add_payment_info', payload);
  }

  dispatchPurchaseSuccess(payload: DataLayerPayload) {
    this.dispatchCustomEvent('subscription_success', payload);
  }

  dispatchPaymentFailed(payload: DataLayerPayload) {
    this.dispatchCustomEvent('payment_failed', payload);
  }

  dispatchBankSlipGenerated(payload: DataLayerPayload) {
    this.dispatchCustomEvent('bankslip_generated', payload);
  }

  dispatchSubscriptionSuccess(payload: DataLayerPayload) {
    this.dispatchCustomEvent('subscription_success', payload);
  }

  dispatchAddToCartFBPixel(payload: DataLayerPayload) {
    this.dispatchCustomEvent('fbpixel_add_to_cart', payload);
  }

  private dispatchCustomEvent(event: string, payload: DataLayerPayload) {
    // Set the event with a payload into the GTM dataLayer
    dataLayer.push({ event, ...payload });
  }

  private buildLeadDataOrder(payload, orderProduct: OrderProduct) {
    const {
      country = 'BR',
      zipcode = '',
      state = '',
      city = '',
      line1 = '',
      line2 = '',
      number = '',
      neighborhood = '',
      fullname = '',
      email = '',
      documenttype = 'cpf',
    } = payload;
    const generatedLead = {
      value: orderProduct.price,
      currency: 'BRL',
      billing_data: {
        country,
        zipcode,
        state,
        city,
        line1,
        line2,
        number,
        neighborhood,
        fullname,
        email,
        documenttype
      },
    }

    return generatedLead;
  }

  private buildLeadDataSubscription(payload, subscriptionPlan: UHSubscription) {
    const {
      country = 'BR',
      zipcode = '',
      state = '',
      city = '',
      line1 = '',
      line2 = '',
      number = '',
      neighborhood = '',
      fullname = '',
      email = '',
      documenttype = 'cpf',
    } = payload;
    const generatedLead = {
      value: subscriptionPlan.TotalWithDiscount,
      currency: 'BRL',
      billing_data: {
        country,
        zipcode,
        state,
        city,
        line1,
        line2,
        number,
        neighborhood,
        fullname,
        email,
        documenttype
      },
    }

    return generatedLead;
  }

  private beginCheckoutProductItemBuilder(orderProduct: OrderProduct, coupon, affiliation) {
    const item: DataLayerGA4ItemModel = {
      affiliation,
      coupon,
      item_id: orderProduct.productid.toString(),
      item_name: orderProduct.title,
      price: Number(orderProduct.price),
      quantity: orderProduct.quantity,
      discount: 0,
    };

    return item;
  }

  private beginCheckoutSubscriptionItemBuilder(subscription: UHSubscription, coupon, affiliation) {
    const item: DataLayerGA4ItemModel = {
      affiliation,
      coupon,
      item_id: subscription.subsplanid.toString(),
      item_name: subscription.subscriptionplan.name,
      price: Number(subscription.TotalWithDiscount),
      quantity: subscription.quantity,
      discount: 0,
    };

    return item;
  }
}