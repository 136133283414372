import { Injectable } from '@angular/core';

import { ApiService } from 'uh-core';

import { Order, OrderProduct } from '../models/order.model';
import { PaymentData } from 'app/payment/payment.model';

import { PurchaseCustomData } from '../models';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { take, tap, switchMap, catchError } from 'rxjs/operators';

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  order$ = new BehaviorSubject<Order>(null);
  onAppliedCoupon$ = new BehaviorSubject<boolean>(null);

  get order() {
    return this.order$ && this.order$.value;
  }

  get totalPrice() {
    if (!this.order) {
      return 0;
    }

    return this.order.orderproducts.reduce((rx, x) => {
      const price = x.chosenprice.price; // original price
      return rx + parseFloat(price) * x.quantity;
    }, 0);
  }

  get totalWithDiscount() {
    return this.order.orderproducts.reduce((rx, op) => {
      return rx + parseFloat(op.subtotal);
    }, 0);
  }

  coupon: string;

  notAppliedDiscount: number;
  hasNotAppliedDiscount: boolean;

  constructor(private api: ApiService) { }

  getNewOrder() {
    return this.api.get('/order/neworder', true).pipe(tap(res => this.order$.next(res.dict.order)));
  }

  getOrderById(orderid: number, updateOrder = false) {
    return this.api.get(`/order/getorderbyid/${orderid}`, true).pipe(
      tap(res => {
        if (updateOrder) {
          const ops: [] = res.dict.order.orderproducts;

          res.dict.order.orderproducts = ops.sort((opa: OrderProduct, opb: OrderProduct) => {
            const tmsDateA = new Date(opa.datecreated).getTime();
            const tmsDateB = new Date(opb.datecreated).getTime();

            if (tmsDateA < tmsDateB) {
              return -1;
            }

            if (tmsDateA > tmsDateB) {
              return 1;
            }

            return 0;
          });


          this.order$.next(res.dict.order);

          // Add "order" to GTM dataLayer
          dataLayer.push({
            order: res.dict.order,
            totalprice: this.totalPrice,
            currency: 'R$'
          });
        }
      }),
      take(1)
    );
  }

  getOrderFormData() {
    return this.api.get(`/order/getorderformdata`, true).pipe(take(1));
  }

  setOrderHandler(orderhandlerid: number) {
    return this.api.get(`/order/setorderhandler/${this.order.orderid}/${orderhandlerid}`, true).pipe(take(1));
  }

  requestOrderPayment(data: PaymentData) {
    return this.api.post(`/order/requestorderpayment/${this.order.orderid}`, data, true).pipe(take(1));
  }

  checkOrderPaid() {
    return this.api.get(`/order/checkorderpaid/${this.order.orderid}`, true).pipe(take(1));
  }

  addProductToOrder(productid: number, priceid: number) {
    return this.requestAndUpdateOrder(
      'GET',
      `/order/addproducttoorder/${this.order.orderid}/${productid}/${priceid}`,
      true
    ).pipe(take(1));
  }

  removeProductFromOrder(productid: number, autorefresh = true) {
    const observable = !autorefresh
      ? this.api.get(`/order/removeproducttoorder/${this.order.orderid}/${productid}`, true)
      : this.requestAndUpdateOrder('GET', `/order/removeproducttoorder/${this.order.orderid}/${productid}`, true);

    return observable.pipe(take(1));
  }

  checkoutOrderById(update = true): Observable<any> {
    if (!update) {
      this.api.get(`/order/checkout/${this.order.orderid}`, true).pipe(take(1));
    }

    return this.requestAndUpdateOrder('GET', `/order/checkout/${this.order.orderid}`, true).pipe(take(1));
  }

  cancelCheckoutByOrderId() {
    return this.requestAndUpdateOrder('GET', `/order/cancelcheckout/${this.order.orderid}`, true).pipe(take(1));
  }

  updateOrderProductById(orderproductid: number, data: PurchaseCustomData) {
    return this.requestAndUpdateOrder('POST', `/order/updateorderproductbyid/${orderproductid}`, true, data).pipe(
      take(1)
    );
  }

  updateOrderOwner(orderid: number, update = true) {
    if (!update) {
      return this.api.get(`/order/changeowner/${orderid}`, true);
    }

    return this.requestAndUpdateOrder('GET', `/order/changeowner/${orderid}`, true);
  }

  requestAndUpdateOrder(rtype: 'GET' | 'POST', endpoint: string, auth?: boolean, data?: any) {
    const requestTypeMapping = {
      GET: this.api.get(endpoint, auth),
      POST: this.api.post(endpoint, data, auth)
    };

    return requestTypeMapping[rtype].pipe(
      catchError(err => {
        if (err.indexOf('is not owner') >= 0) {
          // Resolve issue then do request again
          return this.updateOrderOwner(this.order.orderid, false).pipe(switchMap(() => requestTypeMapping[rtype]));
        }

        return throwError(err);
      }),
      tap(() => this.updateOrder()),
      take(1)
    );
  }

  updateOrder() {
    this.getOrderById(this.order.orderid, true).subscribe(
      res => res.dict.order,
      err => console.log('ERROR [updating order]:', err)
    );
  }

  configCouponDiscountWithoutApply(promotioncoupon) {
    if (promotioncoupon && promotioncoupon != undefined) {
      if (promotioncoupon.discountcoupontype.internalname == 'percentage') {
        const percentage = promotioncoupon.value / 100;
        const calculatedprice = this.totalPrice - (this.totalPrice * percentage);
        this.notAppliedDiscount = calculatedprice;
        this.onAppliedCoupon$.next(true);
        return this.hasNotAppliedDiscount = false;

      } else if (promotioncoupon.discountcoupontype.internalname == 'amount') {
        const calcuatedlprice = this.totalPrice - promotioncoupon.value;
        this.notAppliedDiscount = calcuatedlprice;
        this.onAppliedCoupon$.next(true);
        return this.hasNotAppliedDiscount = false
      }
    }
    this.onAppliedCoupon$.next(false);
    return this.hasNotAppliedDiscount = true;
  }
}
