export const getSubscriptionCoupons = `
query getSubscriptionCoupons($subscriptionPlanId: Int!) {
  SubscriptionPlan  {
    item(subscriptionPlanId: $subscriptionPlanId) {
      internalname
      discountcoupons {
        discountcouponid
        name
        couponcode
      }
    }
  }
}
`;