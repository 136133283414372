import { IBillingInfo } from '../order/models';

// Types
export type Paymethod = 'vindi' | 'vindiboleto' | 'unhide' | 'mock';

export interface PaymentInfo extends IBillingInfo {
  installments: string;
  gatewaytoken: string;
}

export class PaymentData implements PaymentInfo {
  installments;
  gatewaytoken;
  country;
  zipcode;
  state;
  city;
  line1;
  line2;
  number;
  neighborhood;
  fullname;
  email;
  documenttype;
  document;

  constructor(bi: IBillingInfo, installments = '1', gatewaytoken = '') {
    if (bi != null) {
      this.installments = installments;
      this.gatewaytoken = gatewaytoken;

      // Billing Info
      this.country = bi.country;
      this.zipcode = bi.zipcode;
      this.state = bi.state;
      this.city = bi.city;
      this.line1 = bi.line1;
      this.line2 = bi.line2;
      this.number = bi.number;
      this.neighborhood = bi.neighborhood;
      this.fullname = bi.fullname;
      this.email = bi.email;
      this.documenttype = bi.documenttype;
      this.document = bi.document;
    }
  }
}
