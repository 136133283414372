import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { LocalstorageService, ApiService, UserManagementService, ApiResponse } from 'uh-core';

import { environment } from 'environments/environment';
import { BillingFormModel, CountryList } from 'app/order/models';
import { tap, map, take } from 'rxjs/operators';

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private storagekey = environment.storage.keys.uhuser;

  user$ = new BehaviorSubject(this.ls.getItem(this.storagekey));
  countries$ = new BehaviorSubject([]);

  get user(): any {
    return this.user$.value;
  }
  set user(value) {
    this.ls.setItem(environment.storage.keys.uhuser, JSON.stringify(value));
    this.user$.next(value);
  }

  constructor(private ls: LocalstorageService, private api: ApiService, private ums: UserManagementService) { }

  getCountries() {
    return this.api.get('/user/getaddressformdata', true).pipe(
      map(res => res as ApiResponse<CountryList>),
      tap(({dict}) => this.countries$.next(dict.countries)),
      take(1)
    );
  }

  checkUserByEmail(email: string) {
    return this.api.get(`/user/getbyemail/${email}`);
  }

  createQuickUser(billingdata: BillingFormModel, countryid) {
    return this.api.post(
      `/login/postQuickSignup`,
      {
        agree: true,
        fullname: billingdata.fullname,
        alias: billingdata.username,
        username: billingdata.username,
        email: billingdata.email,
        country: countryid
      },
      false
    );
  }

  getAvatar() {
    return this.ums.getAvatar().pipe(
      tap(res => {
        this.user = res.dict.user;
        dataLayer.push({ user: res.dict.user });
      })
    );
  }
}
