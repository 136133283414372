import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Discountcoupon } from 'app/subscription/models/subscription.model';

const defaultDiscountCouponForm = new FormGroup({
  couponcode: new FormControl(null, Validators.required)
});

@Component({
  selector: 'uh-discount-coupon-form',
  templateUrl: './discount-coupon-form.component.html',
  styleUrls: ['./discount-coupon-form.component.scss']
})
export class DiscountCouponFormComponent {
  private _discountcoupon: Discountcoupon = null;

  validated = false;
  validcoupon: boolean = null;

  @Output() applyCoupon = new EventEmitter<string>();
  @Output() removedCoupon = new EventEmitter<boolean>();

  @Input() disableRemoveCoupon = false;

  @Input()
  set dicountcoupon(val: Discountcoupon) {
    this.setCouponCode(val);
  }
  get discountcoupon(): Discountcoupon {
    return this._discountcoupon;
  }

  set couponcode(val: string) {
    this.dcform.controls.couponcode.setValue(val);
  }
  get couponcode(): string {
    return this.dcform.controls.couponcode.value;
  }

  dcform = defaultDiscountCouponForm;

  applyDiscountCoupon(e: KeyboardEvent, couponcode: string) {
    e.preventDefault();
    this.validated = true;
    this.applyCoupon.emit(couponcode.toUpperCase());
  }

  removeDiscountCoupon() {
    this.dcform = defaultDiscountCouponForm;
    this.validated = false;
    this.removedCoupon.emit(true);
  }

  private setCouponCode(dc: Discountcoupon) {
    this._discountcoupon = dc;
    this.couponcode = dc != null ? dc.couponcode : null;
    this.validcoupon = dc != null && dc.discountcouponid > 0;
  }
}
