import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';
import { CCCVCDirective, CCExpirationDirective, CCNumberDirective, UHDOCDirective, UHEqualityDirective } from './directives';

@NgModule({
  declarations: [CCCVCDirective, CCExpirationDirective, CCNumberDirective, UHDOCDirective, UHEqualityDirective],
  imports: [],
  exports: [
    CCCVCDirective, CCExpirationDirective, CCNumberDirective, UHDOCDirective, UHEqualityDirective
  ]
})
export class PaymentModule { }
