import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { OrderService } from './order/services/order.service';
import { UserManagementService, AuthorizationService } from 'uh-core';
import { tap, switchMap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { Order, OrderProduct } from './order/models';
import { OriginDataService } from './utilities/services/origin-data.service';
import { NavigationHelpService } from './utilities/services/navigation-helper.service';
import { SubscriptionApiService } from './subscription/services/subscription-api.service';
import { SigninService } from './signin/signin.service';
import { SubscriptionService } from './subscription/services/subscription.service';

interface Product {
  productid: number;
  quantity?: number;
  name: string;
  prices: Price[];
}

interface Price {
  price: string;
  currency: {
    name: string;
    iso: string;
  };
  productpriceid: number;
}

interface SimpleProduct {
  productid: number;
  productpriceid: number;
}

const MOCKPRODUCTS = {
  // conference: {
  //   LOTE2: <SimpleProduct>{ productid: 33, productpriceid: 31 },
  //   inspirationpass: <SimpleProduct>{ productid: 36, productpriceid: 34 },
  //   daypass27: <SimpleProduct>{ productid: 38, productpriceid: 36 },
  //   daypass28: <SimpleProduct>{ productid: 39, productpriceid: 37 },
  //   daypass29: <SimpleProduct>{ productid: 40, productpriceid: 38 },
  //   'submission-estudante': <SimpleProduct>{
  //     productid: 54,
  //     productpriceid: 52
  //   },
  //   'submission-professional': <SimpleProduct>{
  //     productid: 50,
  //     productpriceid: 48
  //   },
  //   'submission-spark-ar': <SimpleProduct>{
  //     productid: 62,
  //     productpriceid: 60
  //   },
  //   'wacom-intuos': <SimpleProduct>{
  //     productid: 57,
  //     productpriceid: 55
  //   }
  // },
  // workshops: {
  //   'kris-costa': <SimpleProduct>{ productid: 41, productpriceid: 39 },
  //   'erik-johansson': <SimpleProduct>{ productid: 43, productpriceid: 41 },
  //   'gabriel-soares': <SimpleProduct>{ productid: 44, productpriceid: 42 },
  //   beeple: <SimpleProduct>{ productid: 45, productpriceid: 43 },
  //   'marti-romances': <SimpleProduct>{ productid: 46, productpriceid: 44 },
  //   'jama-jurabaev': <SimpleProduct>{ productid: 47, productpriceid: 45 },
  //   'jana-schirmer': <SimpleProduct>{ productid: 48, productpriceid: 46 },
  //   'rafael-vallaperde': <SimpleProduct>{ productid: 49, productpriceid: 47 },
  //   'vetor-zero': <SimpleProduct>{ productid: 51, productpriceid: 49 },
  //   'natalia-taffarel': <SimpleProduct>{ productid: 52, productpriceid: 50 },
  //   'fernando-reule': <SimpleProduct>{ productid: 53, productpriceid: 51 }
  // },
  school: {
    monthly: <{ subsplanid: number, subsplanpriceid: number }>{ subsplanid: 12448, subsplanpriceid: 12446 },
    monthlyenterprise: <{ subsplanid: number, subsplanpriceid: number }>{ subsplanid: 12451, subsplanpriceid: 12448 }
  },
  schoolbundles: {
    quarterly: <SimpleProduct>{ productid: 142, productpriceid: 140 },
    semiyearly: <SimpleProduct>{ productid: 143, productpriceid: 141 },
    yearly: <SimpleProduct>{ productid: 144, productpriceid: 142 }
  }
};

@Component({
  template: `
    <div class="text-center p-5">
      <h1 class="mt-5 pb-5">DEVELOPER TOOL - ORDER GENERATOR</h1>

      <div class="row mt-5">
        <div class="col">
          <h4>SCHOOL BUNDLES:</h4>
          <button
            (click)="addProduct(item.value)"
            class="btn btn-unhide mt-3 mr-1 ml-1"
            *ngFor="let item of products.schoolbundles | keyvalue"
          >
            {{ item.key }}
          </button>

          <h4 class="mt-5">SCHOOL:</h4>
          <button
            (click)="subscribeToPlan(item.value)"
            class="btn btn-unhide mt-3 mr-1 ml-1"
            *ngFor="let item of products.school | keyvalue"
          >
            {{ item.key }}
          </button>

        <div class="col">
          ORDER PRODUCTS: <br />
          <p *ngFor="let op of orderproducts">
            <strong>{{ op.title }} | qty: {{ op.quantity }}</strong>
            <button (click)="removeProduct(op.productid)" class="btn btn-danger ml-3">
              &times;
            </button>
          </p>
        </div>
      </div>
      <br /><br /><br /><br />
      <div class="text-left p-5" style="max-width: 600px; margin:0 auto">
        <div class="input-group">
          <div class="input-group-append">
            <button (click)="checkoutOrder()" class="btn btn-unhide">
              INICIAR O CHECKOUT
            </button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class TestGenerateOrderComponent implements OnDestroy {
  products = MOCKPRODUCTS;
  subs = new Subscription();
  order: Order;

  @ViewChild('originInput') originInput: ElementRef;

  get orderproducts(): OrderProduct[] {
    return this.order != null ? this.order.orderproducts : null;
  }

  constructor(
    private os: OrderService,
    private ums: UserManagementService,
    private as: AuthorizationService,
    private ods: OriginDataService,
    private nhs: NavigationHelpService,
    private sapis: SubscriptionApiService,
    private sbs: SubscriptionService,
    private ss: SigninService
  ) {
    this.generateOrder();

    this.subs.add(
      this.os.order$.subscribe(order => {
        this.order = order;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  generateOrder() {
    this.ums
      .anonymousLogin()
      .pipe(
        tap((res: any) => this.as.setAuthToken(res.dict.token)),
        switchMap(() => this.os.getNewOrder())
      )
      .subscribe();
  }

  addProduct(product: SimpleProduct) {
    this.os
      .addProductToOrder(product.productid, product.productpriceid)
      .subscribe(res => console.log(res), err => console.log(err));
  }

  removeProduct(productid: number) {
    this.os.removeProductFromOrder(productid, true).subscribe(res => console.log(res), err => console.log(err));
  }

  checkoutOrder() {
    // const oi: HTMLInputElement = this.originInput.nativeElement;

    this.ods.setOriginData({
      token: this.as.getAuthToken(),
      orderid: this.os.order.orderid,
      origin: ''
    });

    this.nhs.navigateWithOriginData('order');
  }

  subscribeToPlan(simpleSubscription) {
    this.ss.login('arie', 'nausiodw1')
      .pipe(
        switchMap(() => this.sbs.getNewSubscription(simpleSubscription)),
        // switchMap(res => {
        //   const subs = res.dict.subscriptions != null && res.dict.subscriptions[0];

        //   if (subs && subs != null && subs.state.internalname === 'new') {
        //     throw new Error('Subscription state is not "new"');
        //   }

        //   return this.sbs.getNewSubscription(simpleSubscription);
        // })
      )
      .subscribe(subs => {
        this.ods.setOriginData({
          token: this.as.getAuthToken(),
          subscriptionid: subs.subscriptionid,
          origin: 'unhideschool:membership'
        });

        this.nhs.navigateWithOriginData('subscription');
      });
  }
}
