export const FetchActivePromotionQuery = `
query FetchCampaign {
  Campaign {
    all(active: true) {
      items {
        id
        campaignName
        discountCouponId
        active
        discountCoupon {
          discountcouponid
          active
          name
          couponcode
          value
          validuntil
          datecreated
          discountcoupontype {
            internalname
          }
        }
      }
    }
  }
}
`;
