import { Injectable } from '@angular/core';

import { ApiService } from 'uh-core';

import { throwError, Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';

interface ViacepJsonResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
}

@Injectable({
  providedIn: 'root'
})
export class CepService {
  constructor(private API: ApiService) { }

  getAddressByCep(cep: string | number): Observable<ViacepJsonResponse> {
    const headers = { 'X-Skip-Interceptor': '' };
    return this.API.externalRequest(
      'get',
      `https://viacep.com.br/ws/${cep}/json/`,
      null,
      null,
      null,
      headers
    ).pipe(
      switchMap((res: any) => {
        if (res.erro) {
          return throwError(new Error('o CEP fornecido é inválido!'));
        }

        return of(res);
      }),
      take(1)
    );
  }

  fetchStates(){
    return this.API.externalRequest('post', 'https://countriesnow.space/api/v0.1/countries/states', {country: 'Brazil'})
  }
}
