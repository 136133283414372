import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map, switchMap, catchError, first, tap } from 'rxjs/operators';

// import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { AuthGuardService, UserService } from 'app/utilities/services';
import { SubscriptionService } from './subscription.service';
import { of, throwError } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionCanActivateGuard implements CanActivate {
    constructor(
        private store: CheckoutStoreService,
        private ags: AuthGuardService,
        private sbs: SubscriptionService,
        private us: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {
        const resolveAuth = this.ags.canActivate(route);

        if (this.sbs.subscriptions.length > 0) {
            return resolveAuth;
        }

        return resolveAuth.pipe(
            tap(() => this.sbs.getUserSubscriptions()),
            switchMap(() => {
                return this.sbs.subscriptions$;
            }),
            first(subs => subs.length > 0),
            tap(subs => {
                this.clearStore();
                this.store.subscription = { data: subs[0], valid: true };
            }),
            map(subs => subs != null)
        );
    }

    clearStore() {
        const subscription = this.sbs.subscriptions[0];
        const subscriptionid = subscription != null ? subscription.subscriptionid : -1;

        const storedsubscription = this.store.subscription.data;
        const storedsubsid = storedsubscription != null ? storedsubscription.subscriptionid : -2;

        const shouldClearStore = subscriptionid !== storedsubsid;

        if (shouldClearStore) {
            this.store.clear(true);
        }
    }
}
