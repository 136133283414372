import { Component, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';

import { Subscription,  throwError } from 'rxjs';
import { switchMap, tap, catchError, take } from 'rxjs/operators';
import { DiscountCouponsService } from 'app/order/services/discount-coupons.service';
import { RouteDataService } from 'app/utilities/services/route-data.service';
import { Order, DiscountCoupon } from 'app/order/models';
import { OrderService } from 'app/order/services/order.service';

@Component({
  selector: 'uh-discount-coupon',
  templateUrl: './discount-coupon.component.html',
  styleUrls: ['./discount-coupon.component.scss']
})
export class DiscountCouponComponent implements OnChanges {
  @Input() uhOrder: Order;
  @Input() uhDisableRemoveCupom: boolean;
  @Input() uhAutoApply: string = null;

  @ViewChild('dcinput') input: ElementRef;
  @ViewChild('dcbutton') button: ElementRef;

  subs: Subscription;

  loading = false;
  hasspecialcoupon = false;
  validcoupon: boolean = null;
  discountcoupon: DiscountCoupon;

  autoaplied = false;

  get hasdiscountcoupon() {
    return this.uhOrder.orderproducts.some(op => op.discountcoupon != null);
  }

  get _discountcoupon() {
    const op = this.uhOrder.orderproducts.find(_op => _op.discountcouponid != null);
    return op != null ? op.discountcoupon : null;
  }

  constructor(private dcs: DiscountCouponsService, private rd: RouteDataService, private ods: OrderService) {
    this.checkAutoApplicableCupons();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uhOrder && this.uhOrder.orderproducts.length > 0) {
      if (this.hasdiscountcoupon) {
        this.discountcoupon = this._discountcoupon;
        this.validcoupon = this.discountcoupon != null;
      } else {
        this.autoaplied = false;
        this.discountcoupon = null;
        this.validcoupon = undefined;
      }
    }

    if (changes.uhAutoApply && this.uhAutoApply != null) {
      this.applyCupon(this.uhAutoApply);
    }
  }

  checkAutoApplicableCupons() {
    this.dcs.checkAutoApplicableCupons().subscribe(
      res => {
        (this.autoaplied = true)
      }, err => {
        (this.autoaplied = false)
      }
    );
  }

  validateCoupom() {
    const inputEl: HTMLInputElement = this.input.nativeElement;
    this.applyCupon(inputEl.value);
  }

  applyCupon(code: string) {
    this.loading = true;

    this.dcs
      .clearCouponFromOrder()
      .pipe(
        switchMap(() => this.dcs.applyCouponToOrder(code.toUpperCase())),
        take(1),
        switchMap(res => {
          if (res) {
            return this.dcs.getPromoInfo(code).pipe(
              tap(promotioncoupon => {
                this.ods.configCouponDiscountWithoutApply(promotioncoupon)
              })
            )
          }
        }),
        catchError(e => throwError(e)),
      )
      .subscribe(
        res => {
          this.validcoupon = true;
          this.ods.order$.next(this.ods.order);

        }, //
        err => (this.validcoupon = false), //
        () => (this.loading = false)
      );
  }

  clearCoupon() {
    this.dcs.clearCouponFromOrder().subscribe(() => (this.validcoupon = undefined));
  }
}
