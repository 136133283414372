import { Component, OnInit, Input } from '@angular/core';
import { OrderProduct, DiscountCoupon } from 'app/order/models/order.model';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';

@Component({
  template: '',
  styleUrls: ['./order-product.component.scss']
})
export class OrderProductComponent implements OnInit {
  @Input() uhOrderProduct: OrderProduct;
  @Input() uhOrderOrigin: string;

  @Input() uhTotalPrice = 0;
  @Input() uhDisabled = false;

  title: string;
  subtitle: string;
  discountcoupon: DiscountCoupon;

  installments: number;


  constructor(
    private cs: CheckoutStoreService
  ) {
    this.cs.store$.subscribe(store => {
      if (store.creditcardData.data) {
        this.installments = Number(store.creditcardData.data.installments);
      }
    })
  }

  ngOnInit() {
    this.prepareOrderProduct();
  }

  arrayIncludesValues(arr: string[], ...values) {
    if (Array.isArray(values[0])) {
      values = <string[]>values[0];
    }
    return values.every(i => arr.includes(i));
  }

  prepareOrderProduct() {
    this.title = this.uhOrderProduct.title;
    this.subtitle = this.extractContent(this.uhOrderProduct.description);

    if (this.uhOrderProduct.discountcoupon != null) {
      this.discountcoupon = this.uhOrderProduct.discountcoupon;
    }
  }

  extractContent(s) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
}
