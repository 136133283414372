import { Injectable } from '@angular/core';

import {
  DataLayerPaymentTypeSubscriptionPlan,
  DataLayerPaymentTypeSubscription,
  DataLayerPayload
} from 'app/utilities/services/models/gtm.model';

@Injectable({
  providedIn: 'root'
})
export class DataLayerSubscriptionService {

  constructor(
    
  ) {}

  getPurchaseDataForDatalayer(
    payment_type: string, 
    payment_method: string, 
    subscription: any, 
    origindata: any,
    discount_coupon: any
    ): DataLayerPayload {

    const coupon = discount_coupon && discount_coupon.couponcode || '';
    const totalprice =  discount_coupon != null ? discount_coupon.discountedprice : subscription.price.price;

    const ecommerceProduct = {
        id: subscription.subscriptionplan.subsplanid,
        name: subscription.subscriptionplan.name,
        price: totalprice,
        brand: 'UNHIDE',
        quantity: 1,
        coupon
    };

    // dataLayer - Subscription Plan
    const dataLayerSubscriptionPlanData: DataLayerPaymentTypeSubscriptionPlan = {
        id: subscription.subscriptionplan.subsplanid,
        name: subscription.subscriptionplan.name,
        description: subscription.subscriptionplan.description.replace(/^<p>(.*)<\/p>$/g, '$1'),
        price: subscription.price.price
    };

    // dataLayer - Subscription
    const dataLayerSubscriptionData: DataLayerPaymentTypeSubscription = {
        id: subscription.subscriptionid,
        totalprice,
        totalproducts: 1,
        coupon: ecommerceProduct.coupon,
        subscriptionplan: dataLayerSubscriptionPlanData
    };

    return {
        payment_type,
        payment_method,
        products: [ecommerceProduct],
        purchase: dataLayerSubscriptionData,
        ecommerce: {
            purchase: {
                actionField: {
                    id: ecommerceProduct.id,
                    affiliation: origindata.origin,
                    revenue: totalprice,
                    tax: 0,
                    shipping: 0,
                    coupon: coupon
                },
                products: [ecommerceProduct]
            }
        }
    };
 
  }
}
