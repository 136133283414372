import { Injectable } from '@angular/core';
import { LocalstorageService, ApiService, UserManagementService, AuthorizationService } from 'uh-core';
import { environment } from 'environments/environment';
import { tap, switchMap, map, take } from 'rxjs/operators';
import { OrderService } from 'app/order/services/order.service';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { UserService } from 'app/utilities/services';
import { NavigationHelpService } from 'app/utilities/services/navigation-helper.service';
import { SubscriptionService } from 'app/subscription/services/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SigninService {
  private uhUserStorageKey = environment.storage.keys.uhuser;

  get isAnonymousUser() {
    const user = JSON.parse(this.ls.getItem(this.uhUserStorageKey));
    return user.rolename === 'temporary_user';
  }

  get orderstate() {
    return this.os.order.orderstate;
  }

  constructor(
    private ls: LocalstorageService,
    private api: ApiService,
    private us: UserService,
    private os: OrderService,
    private ods: OriginDataService,
    private as: AuthorizationService,
    private router: Router,
    private subs: SubscriptionService,
    private nhs: NavigationHelpService
  ) { }

  setCurrentOwnerAuthToken(token) {
    try {
      this.as.setAuthToken(token);
      this.ods.setOriginDataProp('token', token);
      return of(true);
    } catch (error) {
      return throwError(error);
    }
  }

  login(username: string, password: string, orderid?: number, subscriptionid?: number) {
    return this.api.post(`/login/login`, { username, password }, true).pipe(
      switchMap(res => {
        if (subscriptionid) {
          return this.changeSubscriptionOwnerByToken(res.dict.token, subscriptionid)
        }
        return this.changeOrderOwnerByToken(res.dict.token, orderid)
      }), // Get/Check user
      take(1)
    );
  }

  changeOrderOwnerByToken(token: string, orderid?: number) {
    return this.setCurrentOwnerAuthToken(token).pipe(
      tap(() => this.ods.syncOriginDataToken()),
      switchMap(() => this.us.getAvatar()),
      switchMap(res => {
        const updateOrder = this.os.updateOrderOwner(orderid).pipe( // Update order
          map(() => res.dict.user), // Map to user data,
        );

        return orderid != null ? updateOrder : of(res.dict.user);
      }),
      take(1)
    );
  }


  changeSubscriptionOwnerByToken(token: string, subscriptionid?: number) {
    return this.setCurrentOwnerAuthToken(token).pipe(
      tap(() => this.ods.syncOriginDataToken()),
      switchMap(() => this.us.getAvatar()),
      switchMap(res => this.subs.updateSubscriptionOwner(subscriptionid)),
      take(1)
    );
  }

  navigateWithOriginData(path: string) {
    const origindata = this.ods.getOriginData();
    const updatedhash = this.ods.originDataToHash(origindata);

    this.router.navigateByUrl(
      this.router.createUrlTree(
        [path], { queryParams: { _c: updatedhash } }
      )
    );
  }

  forgotPassword(email) {
    return this.api.post('/login/forgotpassword', { email }, false);
  }
}
