import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Paymethod } from 'app/payment/payment.model';
import { CreditcardFormModel } from 'app/payment/models/creditcard.model';

import { Subscription } from 'rxjs';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { PaymentService } from 'app/payment/services/payment.service';
import { RouteDataService } from 'app/utilities/services/route-data.service';
import { OriginDataService } from '../../../../utilities/services/origin-data.service';
import { Order } from 'app/order/models';
import { OrderService } from 'app/order/services/order.service';
import { GoogleTagManagerService } from 'app/utilities/services/google-tag-manager.service';


@Component({
  selector: 'uh-payment-options',
  styleUrls: ['payment-options.component.scss'],
  templateUrl: './payment-options.component.html'
})
export class PaymentOptionsComponent implements OnInit, OnDestroy {
  order: Order;
  paymethod: Paymethod;
  creditcardData: CreditcardFormModel;

  maxInstallments = 0;
  selectedInstallment = '1';
  openpanel = 'vindi';
  installments = [];

  totalvalue = 0;
  totalWithDiscount = 0;

  isFormFilled = false;

  subs = new Subscription();

  @Output() paymentTypeChanged = new EventEmitter<Paymethod>();

  @ViewChild('f') form: NgForm;

  get fromEmptyModel(): CreditcardFormModel {
    return new CreditcardFormModel();
  }

  get fromStoredModel(): CreditcardFormModel {
    return { ...this.cs.store.creditcardData.data };
  }

  get isconference() {
    return this.rd.origin === 'unhideconference:inspiration';
  }

  get billingcountry() {
    const billingdata = this.cs.get('billingData').data;
    return billingdata && billingdata.country;
  }

  get origin(): string {
    return this.ors.getOriginData().origin;
  }

  get hasDiscountCupon() {
    return this.order.orderproducts.some(op => {
      return op.discountcoupon != null;
    });
  }

  constructor(
    private cs: CheckoutStoreService,
    private paymentService: PaymentService,
    private orderService: OrderService,
    private rd: RouteDataService,
    private ors: OriginDataService,
    private gtms: GoogleTagManagerService,
  ) {

    this.subs.add(
      this.orderService.order$.subscribe(order => {
        this.order = order;
        this.totalvalue = this.orderService.totalPrice;
        this.totalWithDiscount = this.orderService.totalWithDiscount;
        this.maxInstallments = this.getInstallmentsByProduct();
        this.prepareInstallments();
      })
    );
  }

  ngOnInit() {
    this.updateFormModel();
    this.orderService.checkoutOrderById().subscribe();
    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        this.cs.set('creditcardData', {
          data: this.form.value,
          valid: this.form.valid
        })
      }
      )
    );
  }

  checkIsFormFilled(formValue) {
    return formValue
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getAutoAppliedCupon() {
    if (this.origin !== 'unhideconference:wacom') {
      const couponcode = this.ors.getOriginData().couponcode;
      return couponcode;
    }

    return 'COMBOWACOM2019';
  }

  prepareInstallments() {
    if (this.totalWithDiscount > 0) {
      this.installments = Array.from({ length: this.maxInstallments }, (v, i) => ++i)
        .map((v, i) => {
          const installmentPrice = (this.totalWithDiscount / v).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          });

          return { key: v, value: `${v}x de ${installmentPrice}` };
        })
        .filter(installment => this.totalWithDiscount / installment.key > 5);
    }
  }

  updateFormModel(clear = false) {
    if (!this.cs.store.creditcardData.data || clear) {
      this.creditcardData = this.fromEmptyModel;
    } else {
      this.creditcardData = this.fromStoredModel;
    }
  }



  resetForm() {
    if (!this.form.valid) {
      this.updateFormModel(true);
    }
  }

  // Deprecated | Ariê Furtado - 25-06-2020
  //
  // isLowCostTickets(): boolean {
  //   const origin = this.origin;
  //   const products = []; // this.orderService.order$.value.orderproducts;
  //   switch (origin) {
  //     case 'unhideconference:ticket':
  //       return products.every(product => [36].indexOf(product.productid) > -1);
  //     case 'unhideawards:submission':
  //       return products.every(product => [50, 54].indexOf(product.productid) > -1);
  //     default:
  //       return false;
  //   }
  // }

  private getInstallmentsByProduct(defaultInstallments = 4) {
    const product = this.order.orderproducts[0].product;
    const internalname = product.internalname;

    const installmentsMapping = {
      anual: 12,
      semestral: 6,
      trimestral: 3
    };

    if (internalname in installmentsMapping) {
      return installmentsMapping[internalname];
    }

    return defaultInstallments;
  }

  handlePaymentTypeChange(paymentType: Paymethod) {
    this.openpanel = paymentType;
    this.paymentTypeChanged.emit(paymentType);
  }

  closePaymentPanel(paymentType: Paymethod) {
    if (this.openpanel === paymentType) {
      this.openpanel = ''
    }
  }
}

// UNHIDE48ALPHA
// http://localhost:4200/subscription/billing?_c=eyJvcmlnaW4iOiJ1bmhpZGVzY2hvb2w6bWVtYmVyc2hpcCIsInRva2VuIjoiNWMzZGZhYjJlYjBlM2U0YTBjYmYyYTM0MjZlN2M5ZTNhN2NmMjYzNmRmNTk5MjM5NjYzNjZhNDg0MzViYmMxNiIsInN1YnNjcmlwdGlvbmlkIjoyOTIwfQ%3D%3D